const numberFormatWithPrefix = (number: number) => {
	const prefixes = ["", "K", "M", "B", "T"];
	let i = 0;

	while (number >= 1000 && i < prefixes.length - 1) {
		number /= 1000;
		i++;
	}

	return `${Math.round(number)}${prefixes[i]}`;
};

export default numberFormatWithPrefix;
